<template>
  <div>
    <!-- <el-row :gutter="20" class="search-box">
      <el-col :span="4"> </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row> -->
    <div>
      <el-button class="but" type="primary" @click="showAddMessage"  v-auth="this.per.MESSAGE_ADD"
        >添加公告</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="内容" prop="content"></el-table-column>
      <el-table-column label="发布时间" prop="playTime"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEditMessage(scope.row)" 
             v-auth="this.per.MESSAGE_UPDATA"
          >
            修改
          </span>

          <span class="option option-danger" @click="doDelMessage(scope.row)"  v-auth="this.per.MESSAGE_DEL">
            删除
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="doSumbit"
    ></EditModal>
  </div>
</template>

<script>
import EditModal from "./component/EditModal";
import {
  fetchMessageList,
  updateMessage,
  addMessage,
  delMessage,
} from "@/api/message";
export default {
  components: { EditModal },
  data() {
    return {
      dataSource: [],
      query: {
        isRead: 0,
        updateAt: "",
        title: "",
        type: -1,
        content: "",
        createAt: "",
        playTime: "",
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    showAddMessage() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    showEditMessage(data) {
      this.modalData = this.deepClone(data);
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    doDelMessage(row) {
      this.$confirm("是否要删除这条消息", "提示", {
        type: "warning",
      }).then((res) => {
        delMessage({ id: row.id }).then((res) => {
          this.getList();
        });
      });
    },

    doSumbit(obj) {
      let result = addMessage;
      let msg = "添加成功！";
      if (!this.validatenull(obj.id)) {
        result = updateMessage;
        msg = "修改成功！";
      }

      result(obj).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchMessageList({ query: this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.getList();
  },
};
</script>

import request from '@/common/axios';

// 添加消息
export function addMessage(data) {
    return request({
        url: '/web/admin/message/addMessage',
        method: 'POST',
        data: data
    });
}

// 删除消息
export function delMessage(data) {
    return request({
        url: '/web/admin/message/deleteMessage',
        method: 'POST',
        data:data
    });
}


// 修改消息 
export function updateMessage(data) {
    return request({
        url: '/web/admin/message/updateMessage',
        method: 'POST',
        data:data
    });
}


// 查询消息列表
export function fetchMessageList(data) {
    return request({
        url: '/web/admin/message/adminMessagePage',
        method: 'POST',
        data: data
    });
}
